import * as React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import AboutUs from './components/AboutUs';
import MeetTeam from './components/MeetTeam';
import GetTouch from './components/GetTouch';
import AuditAI from './components/AuditAI';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function Router() {
    const [value, setValue] = React.useState(0);
    const themeB = useTheme();
    const matches = useMediaQuery(themeB.breakpoints.up('md'));

    React.useEffect(() => {

        const routes = [{ name: "About-us", link: "/", activeIndex: 0 },
        { name: "Meet-the-team", link: "/meet-the-team", activeIndex: 1 },
        { name: "Get-in-touch", link: "/get-in-touch", activeIndex: 2 }];

        window.scrollTo({
            top: 0,
            left: 0,
            // behavior: 'smooth'
        });
        [...routes].forEach(route => {
            switch (window.location.pathname) {
                case `${route.link}`:
                    if (value !== route.activeIndex) {
                        setValue(route.activeIndex);
                    }
                    break;
                default:
                    break;
            }
        })
    }, [value]);

    return useRoutes([
        { path: '/', element: (<AboutUs matches={matches} value={value} setValue={setValue} />) },
        { path: '/meet-the-team', element: (<MeetTeam matches={matches} value={value} setValue={setValue} />) },
        { path: '/get-in-touch', element: (<GetTouch matches={matches} value={value} setValue={setValue} />) },
        { path: '/audit-ai', element: (<AuditAI matches={matches} />) },
        { path: '*', element: <Navigate to="/" replace /> },
    ]);
}
