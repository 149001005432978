import React, { useState } from 'react';
import {
    Typography,
    Stack,
    Card,
    CardContent,
    TextField,
    Button,
} from '@mui/material';

import logoAuditAI from "../resources/logo/AuditAILogo.svg";

export default function AuditAI({ matches }) {
    const [email, setEmail] = useState('');
    const [statusMessage, setStatusMessage] = useState('');

    const handleSignup = async () => {
        if (!email) {
            setStatusMessage('Please enter an email.');
            return;
        }

        setStatusMessage('Done.');

        // Replace with your Web App URL from Apps Script
        /*const scriptURL = "https://script.google.com/macros/s/AKfycbxKcnz1B2Pf9SX2FdSyOZihlEorTw2oqYTXlnS3hpk3Q6NDHfzecGudmvzOKtvBsgXu/exec";

        try {
            const response = await fetch(scriptURL, {
                method: 'POST',
                body: JSON.stringify({ email }),
                headers: { 'Content-Type': 'application/json' },
                mode: 'no-cors'
            });

            const result = await response.json();
            if (result.result === 'success') {
                setStatusMessage('Successfully signed up!');
                setEmail('');
            } else {
                setStatusMessage('Something went wrong. Please try again later.');
            }
        } catch (error) {
            console.error(error);
            setStatusMessage('Error submitting email. Please try again.');
        }*/
    };

    return (
        <>
            <Stack sx={{ width: "100%", justifyContent: 'center', display: 'flex' }} direction="column">
                <img
                    alt="logo"
                    src={logoAuditAI}
                    style={{
                        width: '5rem',
                        marginTop: '5rem',
                        marginBottom: '1rem',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                />
                <Typography
                    variant='h3'
                    sx={{
                        marginTop: '3rem',
                        marginBottom: '3rem',
                        color: '#6ca4a4',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    AuditAI
                </Typography>
                <Typography
                    variant='h6'
                    align='center'
                    sx={{
                        marginBottom: '7rem',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    Fast, secure, and affordable smart contract audits.
                </Typography>
                
                <Stack
                    direction={matches ? 'row' : 'column'}
                    sx={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: '10rem',
                        alignItems: 'center',
                    }}
                    spacing={matches ? 4 : 3} 
                >
                    <Card 
                        sx={{ 
                            maxWidth: 280, 
                            minWidth: 250, 
                            p: 2, 
                            boxShadow: 3, 
                            borderRadius: 2, 
                            textAlign: 'center' 
                        }}
                    >
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Save Time and Money
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                AuditAI automates the time-consuming and expensive smart contract auditing process.
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card 
                        sx={{ 
                            maxWidth: 280, 
                            minWidth: 250, 
                            p: 2, 
                            boxShadow: 3, 
                            borderRadius: 2, 
                            textAlign: 'center' 
                        }}
                    >
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Ensure Security
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Identifies vulnerabilities early, mitigating risks and ensuring safer deployment.
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card 
                        sx={{ 
                            maxWidth: 280, 
                            minWidth: 250, 
                            p: 2, 
                            boxShadow: 3, 
                            borderRadius: 2, 
                            textAlign: 'center' 
                        }}
                    >
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Accessibility
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                A user-friendly platform for Soroban developers, even without deep security expertise.
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card 
                        sx={{ 
                            maxWidth: 280, 
                            minWidth: 250, 
                            p: 2, 
                            boxShadow: 3, 
                            borderRadius: 2, 
                            textAlign: 'center' 
                        }}
                    >
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Actionable Insights
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Comprehensive, easy-to-understand reports with clear recommendations.
                            </Typography>
                        </CardContent>
                    </Card>
                </Stack>

                {/* Waiting List Sign-up Section using Google Sheet */}
                <Stack
                    direction="column"
                    alignItems="center"
                    sx={{
                        marginBottom: '5rem',
                    }}
                    spacing={2}
                >
                    <Typography variant="h5" sx={{ color: '#6ca4a4' }}>
                        Join the Waiting List
                    </Typography>
                    <Typography variant="body1" align="center" sx={{ maxWidth: '30rem', marginBottom: '1rem' }}>
                        Be the first to know when AuditAI launches. Sign up below and we’ll keep you updated.
                    </Typography>
                    <Stack direction={matches ? 'row' : 'column'} spacing={1} sx={{ alignItems: 'center' }}>
                        <TextField 
                            label="Email Address" 
                            variant="outlined" 
                            size="small"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            sx={{ width: matches ? '20rem' : '100%' }}
                        />
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleSignup}
                            sx={{ 
                                backgroundColor: '#6ca4a4',
                                ':hover': {
                                    backgroundColor: '#5c9292',
                                }
                            }}
                        >
                            Sign Up
                        </Button>
                    </Stack>
                    {statusMessage && <Typography variant="body2" color="text.secondary">{statusMessage}</Typography>}
                </Stack>
            </Stack>
        </>
    )
}
